import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  bkg: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string, 
}

const defaultProps = {
  className: '',
}

const BackgroundHeader = ({ children, className, bkg }) => (
  <div className={`row ub-background-header bkg-${bkg}`}>
    <div className={`col-12 prefer-max-width ub-background-header-content ${className}`}>{children}</div>
  </div>);

BackgroundHeader.propTypes = propTypes;
BackgroundHeader.defaultProps = defaultProps;

export default BackgroundHeader;