

export const setDocumentTitle = title => document.title = `Wantok Ventures - ${title}`;

export const backgroundImages = [
  '/assets/images/bg/home.png',
  '/assets/images/bg/thank-you.png',
  '/assets/images/bg/others.png',
];



export const pageImages = [
  '/assets/images/logos/wantok-ventures-logo-white.png',
  '/assets/images/logos/wantok-ventures-logo-colored.png',
  '/assets/images/home/wan-png-logo.png',
  '/assets/images/loading/bar-loading.gif'
];


