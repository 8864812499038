const defaultUrls = {
  development: {
      MAIL_SENDER_API_BASE_URL: 'https://localhost:443',
  },
  production: {
      MAIL_SENDER_API_BASE_URL: 'https://wantok-ventures-mail-sender.herokuapp.com',
  },
}

const simulate = 'production';

const getBaseUrls = env => {
  return defaultUrls[env] || defaultUrls.development;
};

export default getBaseUrls(simulate);