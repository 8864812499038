import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BackgroundHeader from '../../BackgroundHeader';

const propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
}

const Container = ({ children, id, history }) => {
  useEffect(() => {
    const { hash } = history.location;
    const el = document.getElementsByTagName('HTML')[0];
    el.style.scrollBehavior = '';
    if (hash && hash !== '') {
      el.style.scrollBehavior = 'smooth';
      setTimeout(
        () => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView(false);
          }
        },
        0
      );
    }
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  });
  return (
    <div className="ub-content">
      <BackgroundHeader bkg={id} className="no-content">
        {children}
      </BackgroundHeader>
    </div>
  );
}


Container.propTypes = propTypes;

export default Container;