import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import Layout from './containers/Shared/Layout';
import NotFound from './containers/Shared/Not_Found';
import Privacy_Policy from './containers/Shared/Privacy_Policy';
import Terms_Of_Use from './containers/Shared/Terms_Of_Use';
import Thank_You from './containers/Shared/Thank_You';
const Home = lazy(() => import('./containers/Home'));
const containers = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/privacy-policy',
    component: Privacy_Policy,
    exact: true,
  },
  {
    path: '/terms-of-use',
    component: Terms_Of_Use,
    exact: true,
  },
  {
    path: '/thank-you',
    component: Thank_You,
    exact: true,
  },
  {
    path: '*',
    component: NotFound,
    exact: false,
  },
];

const propTypes = {
  thankYouPage: PropTypes.object,
};

const defaultProps = {
  thankYouPage: {
    isShown: false,
  },
};

const Routes = (props) => {
  return (
    <Layout {...props}>
      <Suspense fallback={<div className="ring-loader"></div>}>
        <Switch>
          <Redirect from="/home" to="/" />
          <Redirect from="/index" to="/" />
          {containers.map((rc) => (
            <Route
              key={rc.path}
              exact={rc.exact}
              path={rc.path}
              render={(childProps) => <rc.component {...childProps} />}
            />
          ))}
        </Switch>
      </Suspense>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  thankYouPage: state.current.thankYouPage,
});

Routes.propTypes = propTypes;
Routes.defaultProps = defaultProps;

export default connect(mapStateToProps)(withRouter(Routes));
