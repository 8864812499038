import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from '../actions';

const initialState = {
  thankYouPage: {
    isShown: false,
  },
  countryCode: 'us',
}

const reducer = handleActions({
  [actions.setCurrentCountryCode]: (state, action) => ({
    ...state,
    countryCode: action.payload,
  }),
  [actions.setLoading]: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
  [actions.setThankYouPage]: (state, action) => ({
    ...state,
    thankYouPage: {
      ...state.thankYouPage,
      ...action.payload,
    },
  })
}, initialState) 

export default combineReducers({
  current: reducer,
})