import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import { 
    RECAPTCHA_SITE_KEY,
} from '../../settings';
import baseUrls from '../../settings/Urls';
import { setThankYouPage } from '../../stores/actions';

const propTypes = {
    services: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    countryCode: PropTypes.string,
}

const defaultProps = {
    countryCode: 'us',
}


const ContactForm = ({ services, countryCode, history }) => {

    const reCaptchRef = useRef();
    const mobileRef = useRef();
    const instance = axios.create({ baseURL: baseUrls.MAIL_SENDER_API_BASE_URL });
    const source = axios.CancelToken.source();
   
    const [phoneNumber, setPhoneNumber] = useState('');
    const [htmlTemplate, setHtmlTemplate] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
      getHtmlTemplate();
        return () => {
          source.cancel('Cancelled.');
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
  

    const handleSubmit = async evt => {

        evt.preventDefault();
        evt.stopPropagation();

        const form = evt.currentTarget;

        const { name, email, mobile, message } = form;

        const nameFormGroup = document.querySelector('.name-form-group');
        const emailFormGroup = document.querySelector('.email-form-group');
        const mobileFormGroup = document.querySelector('.mobile-form-group');
        const messageFormGroup = document.querySelector('.message-form-group');
        const captchaFormGroup = document.querySelector('.captcha-form-group');
    
        if (name.checkValidity() === false) {
            nameFormGroup.classList.add('was-validated');
            name.focus();
            return;
        } else {
            nameFormGroup.classList.add('was-validated');
        }

        if (email.checkValidity() === false) {

            emailFormGroup.classList.add('was-validated');
            const feedback = emailFormGroup.querySelector('.invalid-feedback');
            if (email.value === '') {
                feedback.innerHTML = "This field is required.";
            } else {
                feedback.innerHTML = "This field should be valid email format.";
            }
            email.focus();
            return;
        } else {
            emailFormGroup.classList.remove('was-validated');
        }


        if (mobile.checkValidity() === false) {
            mobileFormGroup.classList.add('was-validated');
            mobile.focus();
            return;
        } else {
            mobileFormGroup.classList.remove('was-validated');
        }


        if (message.checkValidity() === false) {
            messageFormGroup.classList.add('was-validated');
            message.focus();
            return;
        } else {
            messageFormGroup.classList.remove('was-validated');
        }

        const reCaptchaValue = reCaptchRef.current.getValue() || '';

        if (reCaptchaValue.length < 5) {
            captchaFormGroup.classList.add('was-validated');
            captchaFormGroup.focus();
            return;
        } else {
            captchaFormGroup.classList.remove('was-validated');
        }

        setIsSubmitting(true);

        setError('');

        const nameValue = name.value;
        const emailValue = email.value;
        const mobileValue = mobile.value;
        const messageValue = message.value;

        let template = htmlTemplate;
        template = template.replace(/{name}/i, nameValue);
        template = template.replace(/{email}/i, emailValue);
        template = template.replace(/{mobile}/i, mobileValue);
        template = template.replace(/{message}/i, messageValue);

        const result = await sendMail(nameValue, emailValue, mobileValue, messageValue, template);

        setIsSubmitting(false);

                
        if (result.success) {

            services.setThankYouPage({ isShown: true });
      
            history.push('/thank-you');

        } else {

            setError(result.error);
        }
    }

    const getHtmlTemplate = async ()=> {
        const { data } = await axios.get('/templates/contact-us.html', { cancelToken: source.token } );
        setHtmlTemplate(data);
    }


    const sendMail = async (name, email, mobile, message, html) => {
        try {
            const { data } = await instance.post('/api/form', {
                name,
                email,
                mobile,
                message,
                html,
            });
            return data;
        } catch (err) {
            return {
                trace: err,
                error: 'Network Error: Send message failed.',
                success: false,
            };            
        }
    }

    const handleChange = value => {
        setPhoneNumber(value);
    }

    const handleVerify = value => {
    const captchValue = value || '';
     const captchaFormGroup = document.querySelector('.captcha-form-group');
     if (captchValue.length <  5) {
        captchaFormGroup.classList.add('was-validated');
     } else {
        captchaFormGroup.classList.remove('was-validated');
     }
    }
    return (
        <div className="ub-article ub-contact-us prefer-max-width">
            <div id="contact-us" className="ub-article-card-form">
                <h1 className="ub-article-card-form-header _underline">CONTACT US</h1>
                <div className="error-message">{error}</div>
                <form data-toggle="validator" className="form-horizontal" onSubmit={handleSubmit} noValidate>
                    <div className="name-form-group form-group has-feedback">
                        <input
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            maxLength={255}
                            tabIndex={-1}
                            onClick={evt => evt.currentTarget.focus()}
                            required
                        />
                        <div className="invalid-feedback">This field is required.</div>
                    </div>
                    <div className="email-form-group form-group has-feedback">
                        <input
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Email address"
                            maxLength={255}
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            tabIndex={0}
                            onClick={evt => evt.currentTarget.focus()}
                            onBlur={evt => {
                                evt.preventDefault();
                                mobileRef.current.focus();
                            }}
                            required />
                        <div className="invalid-feedback">This field required a valid email format.</div>
                    </div>
                    <div className="mobile-form-group form-group has-feedback">
                        <PhoneInput
                            disableAreaCodes
                            placeholder="Mobile number"
                            preferredCountries={['us', 'ca', 'au', 'uk', 'fr', 'ae', 'ph', 'sg']}
                            masks={{
                                us: '+. (...) ...-....',
                                ca: '+. (...) ...-....',
                                au: '+.. ... .... ...',
                                fr: '+.. (...) ..-..-..',
                                at: '+.. (....) ...-....'
                            }}
                            value={phoneNumber}
                            defaultCountry={countryCode}
                            onChange={handleChange}
                            inputExtraProps={{
                                ref: mobileRef,
                                name: 'mobile',
                                required: true,
                                tabIndex: 1,
                                onClick: evt => evt.currentTarget.focus()
                            }}
                        />
                        <div className="invalid-feedback">This field is required.</div>
                    </div>
                    <div className="message-form-group form-group has-feedback">
                        <textarea
                            name="message"
                            type="text"
                            className="form-control"
                            placeholder="Message"
                            maxLength={2000}
                            tabIndex={2}
                            onClick={evt => evt.currentTarget.focus()}
                            required
                        />
                        <div className="invalid-feedback">This field is required.</div>
                    </div>
                    <div className="captcha-form-group form-group has-feedback">
                        <ReCAPTCHA
                            ref={reCaptchRef}
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={handleVerify}
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" tabIndex={3} className="btn ub-btn" disabled={isSubmitting}>
                            { isSubmitting ? 'Sending Message...' : 'Send Message'}
                        </button>
                    </div>
                </form>
            </div>
        </div>);
}

ContactForm.propTypes = propTypes;

ContactForm.defaultProps = defaultProps;    

const mapStateToProps = state => ({
    countryCode: state.current.countryCode
})

const mapDispatchToProps = dispatch => ({
    services: bindActionCreators({
        setThankYouPage,
    }, dispatch),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps)(ContactForm);