import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
	<div className="ub-footer">
		<div className="row row-alt-f">
			<div className="col-12">
				<div className="ub-footer-content prefer-max-width">
					<div className="content-left">
						<div className="content-left-top">
							<span className="d-none d-sm-block proud-sponsor">Proud Sponsors of</span>
							<span className="d-block d-sm-none proud-sponsor" style={{ paddingLeft: 25 }}>Proud Sponsors of</span>
						</div>
						<div className="content-left-bottom">
							<img className="wan-png-logo" alt="WAN PNG" src="/assets/images/home/wan-png-logo.png" />
						</div>
					</div>
					<div className="content-right">
						<p className="d-block d-sm-none text-center">For more information, visit <br /><strong style={{ lineheight: 1 }}>www.wanpng.com</strong></p>
						<p className="d-none d-sm-block">For more information, visit <strong>www.wanpng.com</strong></p>
					</div>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-12">
				<div className="ub-footer-content_2 prefer-max-width">
					<div className="row">
						<div className="col-3 col-sm-4">
							<div className="row">
								<div className="col-12" style={{ paddingRight: 5 }}>
									<Link className="navbar-brand" to="/">
										<img className="wv-logo" alt="" src="/assets/images/logos/wantok-ventures-logo-colored.png" height={30} />
									</Link>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
								 <p className="_spacer-xs"></p>
									<ul className="footer-navbar-nav list-group navbar-nav">
										<li className="nav-item">
											<Link className="nav-link" to="/">
												HOME
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/#services">
												SERVICES
											</Link>
										</li>
										<li className="nav-item">
											<Link className="nav-link" to="/#contact-us">
												CONTACT US
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-9 col-sm-8">
							<div className="wv-location">
								<div>
									<h4 className="_dash">PAPUA NEW GUINEA</h4>
									<p>Port Moresby, Ground Floor, Turumu Haus, <br /> Spring Garden Road, Gordons</p>
									<p> Postal address: <br /> PO Box 1413 Port Moresby, NCD</p>
								</div>
								<div>
									<h4 className="_dash">SINGAPORE</h4>
									<p>55 Jervois Road, 08-04 <br /> Singapore 249047</p>
									<div className="d-block d-sm-none get_in_touch">
										<h4 className="_dash">GET IN TOUCH</h4>
										<p>
											<a href="mailto:admin@wantokventures.com" title="admin@wantokventures.com" className="email">admin@wantokventures.com</a>
											<br />+675 7135 2981
										</p>
									</div>
								</div>
								<div className="d-none d-sm-block get_in_touch">
									<h4 className="_dash">GET IN TOUCH</h4>
									<p>
										<a href="mailto:admin@wantokventures.com" title="admin@wantokventures.com" className="email">admin@wantokventures.com</a>
										<br />+675 7135 2981
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row ub-footer-content_2_last_row">
						<div className="col-7">
							<span className="d-none d-sm-block copyright">Wantok Ventures (PNG) Limited (Company Number 1-123226) © 2019. All rights reserved.</span>
							<span className="d-block d-sm-none copyright" style={{ marginLeft: '1em' }}>Wantok Ventures (PNG) Limited <br /> (Company Number 1-123226) © 2019.  <br /> All rights reserved.</span>
						</div>
						<div className="col-5" style={{ paddingRight: '.8vw' }}>
							<ul className="last-nav nav nav-pills mx-auto">
								<li className="nav-item">
									<Link to="/privacy-policy">Privacy Policy</Link>
								</li>
								<li className="nav-item">
									<Link to="/terms-of-use">Terms of Use</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default Footer;
