import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const menus = [{
  id: '001',
  name: 'HOME',
  path: '/',
}, {
  id: '002',
  name: 'SERVICES',
  path: '#services',
}];

const propTypes = {
  history: PropTypes.object.isRequired,
};

const NavigationBar = ({ history }) => {
  const { pathname } = history.location;
  const path = pathname.toLowerCase();
  const handleClick = evt => {
    evt.preventDefault();
    const el = document.getElementById('contact-us');
    if (el) el.scrollIntoView();
    else {
      history.push('/#contact-us');
    }
  }
  return (
    <nav id="ub-main-navbar" className="prefer-max-width navbar navbar-expand-md sticky-top">
      <Link className="navbar-brand" to="/">
        <img className="wv-logo" alt="Wantok Ventures" src="/assets/images/logos/wantok-ventures-logo-white.png" />
      </Link>
      <button type="button"
        className="ub-navbar-toggler navbar-toggler navbar-toggler-right"
        data-toggle="collapse"
        data-target="#collapse_navbar"
        aria-controls="collapse_navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="ub-navbar-toggler-icon navbar-toggler-icon"></span>
      </button>
      <div id="collapse_navbar" className="collapse navbar-collapse">
        <ul className="navbar-nav ml-auto">
          { 
            menus.map(menu => (path.indexOf(menu.path) > -1 ?
              <li key={menu.id} className="nav-item active"><Link className="nav-link" to={menu.path}>{menu.name}</Link></li>
              : <li key={menu.id} className="nav-item"><Link className="nav-link" to={menu.path}>{menu.name}</Link></li>))
          }
          <li className="nav-item fixed"><Link className="nav-link" to="#contact-us" onClick={handleClick}>CONTACT US</Link></li>
        </ul>
      </div>
    </nav>
  );
}


NavigationBar.propTypes = propTypes;

export default NavigationBar;