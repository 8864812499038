import { createAction } from 'redux-actions';
import * as types from './types';

export const setCurrentCountryCode = createAction(
  types.SET_CURRENT_COUNTRY_CODE,
);

export const setLoading = createAction(
  types.SET_LOADING,
);

export const setThankYouPage = createAction(
  types.SET_THANK_YOU_PAGE,
);
