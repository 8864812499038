import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import publicIP from 'public-ip';
import iplocation from 'iplocation';
import Footer from './Footer';
import NavigationBar from '../../../components/NavigationBar';
import { setCurrentCountryCode, setLoading } from '../../../stores/actions';

const propTypes = {
  children: PropTypes.node.isRequired,
  services: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

const Layout = ({ children, services, history }) => {

  useLayoutEffect(() => {

    const getCountryCode = async () => {
      const ip = await publicIP.v4();
      const response = await iplocation(ip);
      const countryCode = (response.countryCode || 'us').toLowerCase();
      services.setCurrentCountryCode(countryCode);
    }

    getCountryCode();

    return () => { }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="ub-layout container-fluid preload-images">
      <div className="ub-navbar-wrapper sticky-top"><NavigationBar history={history} /></div>
      <div className="ub-content-wrapper"> 
         <>{children}</>
      </div>
      <div className="ub-footer-wrapper"><Footer /></div>
    </div>);
}

Layout.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  services: bindActionCreators({
    setCurrentCountryCode,
    setLoading,
  }, dispatch),
})

export default connect(
  null,
  mapDispatchToProps)(Layout);
