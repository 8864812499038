import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BackgroundHeader from '../BackgroundHeader';
import Form from '../Form';

const propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
}

const Container = ({ children, id, content, history }) => {

  useEffect(() => {
    const { hash } = history.location;
    if (hash && hash !== '') {
      setTimeout(
        () => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        },
        0
      );
    } else {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  });
  return (
     <div className="ub-content">
        <BackgroundHeader bkg={id}>
          {content}
        </BackgroundHeader>
        <div className="row">
          <div className="col-12" style={{ minHeight: 0 }}>{children}</div>
        </div>
        <div className="row">
          <div className="col-12">
            <Form history={history} />
          </div>
        </div>
    </div>
  );
}


Container.propTypes = propTypes;

export default Container;