import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './stores/ConfigureStore';
import { loadState, saveState } from './stores/LocalStorage';
import * as serviceWorker from './serviceWorker';
import App from './App';
import './css/index.css';


const stateFromLocalStorage = loadState();

const store = configureStore(stateFromLocalStorage);

render(
  <Provider store={store}>
    <BrowserRouter>
       <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

store.subscribe(() => {
  saveState({
    ...stateFromLocalStorage,
    ...store.getState('state'),
  });
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
