import React, { useEffect } from 'react';
import Container from '../../../components/Container/Empty';
import { setDocumentTitle } from '../../../utils';

const ThankYou = props => {
    useEffect(() => {
        setDocumentTitle('Thank You');
    });
    const handleBack = evt => {
        props.history.push('/');
    }
    return (
        <Container
            id="0002"
            {...props}
        >
            <div className="container text-center"
            >
                <span className="text-center">
                    <h1>Thank You</h1>
                    <br />
                    <p>Your Message has been sent. We will get in touch with you shortly.</p>
                    <br />
                    <br />
                    <button className="btn ub-btn" onClick={handleBack}>Back to home</button>
                </span>
            </div>
        </Container>
    );
}

export default ThankYou;