import React, { useEffect } from 'react';
import Container from '../../../components/Container/Empty';
import { setDocumentTitle } from '../../../utils';


const Home = props => {
    useEffect(() => {
        setDocumentTitle('404');
    });
    const handleBack = evt => {
        props.history.push('/');
    }
    return (
        <Container
            id="0002"
            {...props}
        >
            <div className=" text-center">
                <h4>404</h4>
                <h1>PAGE NOT FOUND</h1>
                <p className="spacer_xs" />
                <p>The page you were looking for doesn’t exist.</p>
                <p className="spacer_xs"></p>
                <button className="btn ub-btn" onClick={handleBack}>Back to home</button>
            </div>
        </Container>
    );
}


export default Home;